<template>
    <v-container fluid>
        <v-row class="tw-mb-4">
            <v-col class="tw-flex tw-gap-4">
                <!-- back button -->
                <v-btn
                    class="tw-rounded-md"
                    color="primary"
                    icon="mdi-arrow-left"
                    size="small"
                    @click="$router.back()">
                </v-btn>
                <div>
                    <h1 class="tw-text-xl tw-font-bold">{{ installationInfo?.name || '' }}</h1>
                    <p
                        v-if="installationInfo?.installation"
                        class="tw-text-gray-500 tw-text-sm">
                        Instalação: [{{ installationInfo?.installation?.platform }}] ({{ installationInfo?.installation?.ref }}) {{ installationInfo?.installation?.name }}
                    </p>
                    <p
                        v-if="installationInfo?.client"
                        class="tw-text-gray-500 tw-text-sm">
                        Cliente: [{{ installationInfo?.client?.nif }}] {{ installationInfo?.client?.name }}
                    </p>
                </div>
            </v-col>
            <v-col class="tw-flex tw-justify-end tw-gap-4">
                <v-btn
                    v-if="!isLoading && installationInfo"
                    color="primary"
                    :loading="isSaving"
                    :readonly="isSaving"
                    @click="saveInstallation">
                    <v-icon class="tw-mr-1">mdi-content-save</v-icon>
                    Guardar
                </v-btn>
            </v-col>
        </v-row>
        <div
            v-if="installationInfo"
            class="tw-relative">
            <div
                v-show="isSaving"
                class="tw-absolute tw-inset-0 tw-z-10 tw-bg-gray-100 tw-opacity-50"></div>
            <v-tabs
                v-model="currentTab"
                color="primary">
                <v-tab value="info">Info</v-tab>
                <v-tab value="contract">Contratos</v-tab>
                <v-tab value="batteries">Baterias</v-tab>
                <v-tab value="panelArea">Painéis</v-tab>
                <v-tab value="replaces">Histórico de substituições</v-tab>
            </v-tabs>
            <v-tabs-window v-model="currentTab">
                <v-tabs-window-item value="info">
                    <v-container
                        class="tw-px-0"
                        fluid>
                        <v-row>
                            <v-col
                                cols="12"
                                lg="6" >
                                <v-row>
                                    <!-- installationInfo.installation.name -->
                                    <v-col
                                        cols="12">
                                        <v-text-field
                                            v-model="installationInfo.name"
                                            label="Nome"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <h3>Localização</h3>
                                    </v-col>
                                    <!-- installationInfo.lat -->
                                    <v-col
                                        cols="12"
                                        sm="6">
                                        <v-text-field
                                            v-model="installationInfo.lat"
                                            label="Latitude"
                                            type="number"
                                            @keyup="onCoordinatesChange"></v-text-field>
                                    </v-col>
                                    <!-- installationInfo.long -->
                                    <v-col
                                        cols="12"
                                        sm="6">
                                        <v-text-field
                                            v-model="installationInfo.long"
                                            label="Longitude"
                                            type="number"
                                            @keyup="onCoordinatesChange"></v-text-field>
                                    </v-col>
                                    <!-- <v-col>
                                        <v-btn
                                            class="tw-rounded-md"
                                            color="primary"
                                            icon
                                            size="small"
                                            @click="isMapOpen = !isMapOpen">
                                            <v-icon v-if="!isMapOpen">mdi-map</v-icon>
                                            <v-icon v-else>mdi-close</v-icon>
                                        </v-btn>
                                    </v-col> -->
                                    <!-- installationInfo.address -->
                                    <v-col
                                        cols="12">
                                        <v-text-field
                                            v-model="installationInfo.address.road"
                                            :disabled="noCoordinates"
                                            label="Morada"></v-text-field>
                                    </v-col>
                                    <!-- installationInfo.city -->
                                    <v-col
                                        cols="12"
                                        sm="6">
                                        <v-text-field
                                            v-model="installationInfo.address.city"
                                            :disabled="noCoordinates"
                                            label="Cidade"></v-text-field>
                                    </v-col>
                                    <!-- installationInfo.postalCode -->
                                    <v-col
                                        cols="12"
                                        sm="6">
                                        <v-text-field
                                            v-model="installationInfo.address.postcode"
                                            :disabled="noCoordinates"
                                            label="Código postal"></v-text-field>
                                    </v-col>
                                    <!-- installationInfo.country -->
                                    <v-col
                                        cols="12"
                                        sm="6">
                                        <v-text-field
                                            v-model="installationInfo.country"
                                            :disabled="noCoordinates"
                                            label="País"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col
                                cols="12"
                                lg="6">
                                <Suspense>
                                    <Map
                                        class="tw-h-96"
                                        :model-value="{
                                            lat: installationInfo.lat as number,
                                            lng: installationInfo.long as number,
                                        }"
                                        @update:model-value="onMarkerUpdate" />
                                </Suspense>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!-- installationInfo.notes -->
                            <v-col cols="12">
                                <v-textarea
                                    v-model="installationInfo.notes"
                                    label="Notas"></v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <h4 class="tw-text-lg tw-font-semibold">Instalação associada</h4>
                            </v-col>
                            <v-col cols="12">
                                <template v-if="isChangingInstallation || !installationInfo.installation">
                                    <v-row
                                        v-if="installationInfo.installation && !isCreating"
                                        class="tw-mb-2">
                                        <v-col cols="12">
                                            <v-alert type="info"> Substituir a instalação irá criar um novo registo de substituição, disponível no histórico de substituições. </v-alert>
                                        </v-col>
                                    </v-row>
                                    <InstallationsTable
                                        v-model="tableSelectedInstallation"
                                        :show-cancel="!!installationInfo.installation"
                                        @cancel="isChangingInstallation = false"
                                        @update:model-value="onInstallationChange" />
                                </template>
                                <template v-else>
                                    <v-row class="tw-mb-2">
                                        <v-col
                                            class="max-sm:tw-order-2"
                                            cols="12"
                                            md="6"
                                            sm="7">
                                            <v-text-field
                                                v-model="installationInfo.installation.name"
                                                label="Nome"
                                                readonly></v-text-field>
                                        </v-col>
                                        <v-col
                                            class="tw-flex tw-justify-end tw-gap-4 max-sm:tw-order-1"
                                            cols="12"
                                            md="6"
                                            sm="5">
                                            <v-btn
                                                v-if="hasChangedInstallation"
                                                @click="resetInstallation">
                                                Repor
                                            </v-btn>
                                            <v-btn @click="beforeChangingInstallation"> Substituir instalação </v-btn>
                                        </v-col>
                                        <!-- REF -->
                                        <v-col
                                            class="max-sm:tw-order-3"
                                            cols="12"
                                            sm="6">
                                            <v-text-field
                                                v-model="installationInfo.installation.ref"
                                                label="REF"
                                                readonly></v-text-field>
                                        </v-col>
                                        <!-- platform -->
                                        <v-col
                                            class="max-sm:tw-order-3"
                                            cols="12"
                                            sm="6">
                                            <v-text-field
                                                v-model="installationInfo.installation.platform"
                                                label="Plataforma"
                                                readonly></v-text-field>
                                        </v-col>
                                        <!-- sysCreated -->
                                        <v-col
                                            class="max-sm:tw-order-3"
                                            cols="12"
                                            md="4"
                                            sm="6">
                                            <v-text-field
                                                label="Data de início"
                                                :model-value="installationInfo.installation.sysCreated ? moment(installationInfo.installation.sysCreated).format('DD/MM/YYYY HH:mm') : 'N/A'"
                                                readonly></v-text-field>
                                        </v-col>
                                        <!-- lastUpdated -->
                                        <v-col
                                            class="max-sm:tw-order-3"
                                            cols="12"
                                            md="4"
                                            sm="6">
                                            <v-text-field
                                                label="Última comunicação"
                                                :model-value="installationInfo.installation.lastUpdated ? moment(installationInfo.installation.lastUpdated).format('DD/MM/YYYY HH:mm') : 'N/A'"
                                                readonly></v-text-field>
                                        </v-col>
                                        <!-- lastFetchedDate -->
                                        <v-col
                                            class="max-sm:tw-order-3"
                                            cols="12"
                                            md="4"
                                            sm="6">
                                            <v-text-field
                                                label="Data da última recolha"
                                                :model-value="installationInfo.installation.lastFetchedDate ? moment(installationInfo.installation.lastFetchedDate).format('DD/MM/YYYY HH:mm') : 'N/A'"
                                                readonly></v-text-field>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tabs-window-item>
                <v-tabs-window-item value="contract">
                    <v-container
                        class="tw-px-0"
                        fluid>
                        <v-row>
                            <v-col cols="12">
                                <h4 class="tw-text-lg tw-font-semibold">Cliente</h4>
                            </v-col>
                        </v-row>
                        <template v-if="isChangingClient || !installationInfo.client">
                            <ClientsTable
                                v-model="tableSelectedClient"
                                :show-cancel="!!installationInfo.client"
                                @cancel="isChangingClient = false"
                                @update:model-value="onClientChange" />
                        </template>
                        <template v-else>
                            <v-row>
                                <v-col
                                    class="max-sm:tw-order-2"
                                    cols="12"
                                    sm="6">
                                    <v-text-field
                                        v-model="installationInfo.client.name"
                                        label="Nome"
                                        readonly></v-text-field>
                                </v-col>
                                <v-col
                                    class="tw-flex tw-justify-end tw-gap-4 max-sm:tw-order-1"
                                    cols="12"
                                    sm="6">
                                    <v-btn
                                        v-if="hasChangedClient"
                                        @click="resetClient">
                                        Repor
                                    </v-btn>
                                    <v-btn @click="beforeChangingClient"> Alterar cliente </v-btn>
                                </v-col>
                                <!-- nif -->
                                <v-col
                                    class="max-sm:tw-order-3"
                                    cols="6"
                                    md="4">
                                    <v-text-field
                                        v-model="installationInfo.client.nif"
                                        label="NIF"
                                        readonly></v-text-field>
                                </v-col>
                                <!-- postalCode -->
                                <v-col
                                    class="max-sm:tw-order-3"
                                    cols="6"
                                    md="4">
                                    <v-text-field
                                        v-model="installationInfo.client.postalCode"
                                        label="Código postal"
                                        readonly></v-text-field>
                                </v-col>
                                <!-- city -->
                                <v-col
                                    class="max-sm:tw-order-3"
                                    cols="6"
                                    md="4">
                                    <v-text-field
                                        v-model="installationInfo.client.city"
                                        label="Cidade"
                                        readonly></v-text-field>
                                </v-col>
                                <!-- country -->
                                <v-col
                                    class="max-sm:tw-order-3"
                                    cols="6"
                                    md="4">
                                    <v-text-field
                                        v-model="installationInfo.client.country"
                                        label="País"
                                        readonly></v-text-field>
                                </v-col>
                            </v-row>
                        </template>
                        <v-row>
                            <v-col cols="12">
                                <v-divider class="tw-mb-4"></v-divider>
                            </v-col>
                        </v-row>
                        <ContractsTable
                            v-model="contracts"
                            :installation-info-id="installationInfoId" />
                    </v-container>
                </v-tabs-window-item>
                <v-tabs-window-item value="batteries">
                    <v-container
                        class="tw-px-0"
                        fluid>
                        <BatteriesTable
                            v-model="batteries"
                            :installation-info-id="installationInfoId" />
                    </v-container>
                </v-tabs-window-item>
                <v-tabs-window-item value="panelArea">
                    <v-container
                        class="tw-px-0"
                        fluid>
                        <PanelAreasTable
                            v-model="panelAreas"
                            :installation-info-id="installationInfoId" />
                    </v-container>
                </v-tabs-window-item>
                <v-tabs-window-item value="replaces">
                    <v-container
                        class="tw-px-0"
                        fluid>
                        <InstallationReplacesTable :installation-info-id="installationInfoId" />
                    </v-container>
                </v-tabs-window-item>
            </v-tabs-window>
        </div>
        <template v-else-if="!isLoading">
            <!-- installationInfo not found -->
            <v-alert
                color="error"
                icon="mdi-alert"
                outlined>
                Instalação não encontrada
            </v-alert>
        </template>
        <template v-else>
            <div class="tw-flex tw-justify-center">
                <v-progress-circular
                    color="primary"
                    indeterminate
                    :size="70"
                    :width="7"></v-progress-circular>
            </div>
        </template>
    </v-container>
    <v-dialog
        v-model="isClosingModalOpen"
        max-width="400">
        <v-card>
            <v-card-title>Guardar alterações</v-card-title>
            <v-card-text>
                <p>Existem alterações por guardar. Tem a certeza que pretende sair?</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="tw-mr-4"
                    variant="text"
                    @click="isClosingModalOpen = false">
                    Não
                </v-btn>
                <v-btn
                    color="primary"
                    variant="flat"
                    @click="onExit">
                    Sim
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import _ from 'lodash';
    import ClientsTable from '../Clients/ClientsTable.vue';
    import ContractsTable from '../Contracts/ContractsTable.vue';
    import BatteriesTable from '../Batteries/BatteriesTable.vue';
    import { createInstallationInfo, getInstallationInfo, updateInstallationInfo } from '@/api/installationsInfos';
    import { Battery, Client, Contract, Installation, InstallationInfo, PanelArea } from '@/contentTypes.d';
    import { ref } from 'vue';
    import { onBeforeRouteLeave, useRoute } from 'vue-router';
    import Map from '@/components/Map.vue';

    import moment from 'moment';
    import { reverseGeoCode } from '@/api/geolocation';
    import PanelAreasTable from '../PanelAreas/PanelAreasTable.vue';
    import InstallationsTable from './InstallationsTable.vue';
    import InstallationReplacesTable from '../InstallationReplaces/InstallationReplacesTable.vue';
    import { useAlert } from '@/composables/useAlert';

    const isSaving = ref<boolean>(false);
    const isLoading = ref<boolean>(false);

    const $route = useRoute();
    const $router = useRouter();
    const $alert = useAlert();
    const currentTab = ref<string>('info');
    const installationInfo = ref<InstallationInfo | null>(null);
    const originalInstallationInfo = ref<InstallationInfo | null>(null);
    const isClosingModalOpen = ref<boolean>(false);
    const toRoute = ref<string>('');
    const forceExit = ref<boolean>(false);
    const isMapOpen = ref<boolean>(false);

    const contracts = ref<{
        _create: Contract[];
        _update: Contract[];
        _delete: Contract[];
    }>({
        _create: [],
        _update: [],
        _delete: [],
    });

    const panelAreas = ref<{
        _create: PanelArea[];
        _update: PanelArea[];
        _delete: PanelArea[];
    }>({
        _create: [],
        _update: [],
        _delete: [],
    });

    const batteries = ref<{
        _create: Battery[];
        _update: Battery[];
        _delete: Battery[];
    }>({
        _create: [],
        _update: [],
        _delete: [],
    });

    const installationInfoId = computed(() => installationInfo.value?.id as number);

    const isCreating = computed(() => $route.params.id === 'new');

    const noCoordinates = computed(() => !installationInfo.value?.lat || !installationInfo.value?.long);

    // #region info
    function onCoordinatesChange() {
        if (installationInfo.value?.lat && installationInfo.value?.long) {
            reverseGeoCode(installationInfo.value?.lat, installationInfo.value?.long).then((res) => {
                if (res.data?.address) {
                    installationInfo.value!.address = {
                        ...res.data.address,
                        city: res.data.address.city || res.data.address.town,
                        road: res.data.address.road || res.data.address.neighbourhood,
                        postcode: res.data.address.postcode,
                    };
                    installationInfo.value!.country = res.data.address.country_code.toUpperCase();
                } else {
                    installationInfo.value!.address = {
                        road: '',
                        city: '',
                        postcode: '',
                    };
                    installationInfo.value!.country = '';
                }
            });
        }
    }

    const isChangingInstallation = ref<boolean>(false);
    const tableSelectedInstallation = ref<InstallationInfo[]>([]);
    const originalInstallation = ref<Installation | null>(null);

    const hasChangedInstallation = computed(() => {
        if (!installationInfo.value || !originalInstallation.value) {
            return false;
        }

        return installationInfo.value.installation?.id !== originalInstallation.value?.id;
    });

    function onInstallationChange() {
        if (tableSelectedInstallation.value.length && installationInfo.value) {
            installationInfo.value.installation = tableSelectedInstallation.value[0];
            isChangingInstallation.value = false;
            tableSelectedInstallation.value = [];
        }
    }

    function onMarkerUpdate(coordinates: { lat: number; lng: number }) {
        if (installationInfo.value) {
            installationInfo.value.lat = coordinates.lat;
            installationInfo.value.long = coordinates.lng;
            onCoordinatesChange();
        }
    }

    function resetInstallation() {
        if (installationInfo.value && originalInstallation.value) {
            installationInfo.value.installation = originalInstallation.value;
            isChangingInstallation.value = false;
        }
    }

    function beforeChangingInstallation() {
        tableSelectedInstallation.value = installationInfo.value!.installation ? [installationInfo.value!.installation] : [];
        isChangingInstallation.value = true;
    }
    // #endregion

    // #region client
    const isChangingClient = ref<boolean>(false);
    const tableSelectedClient = ref<Client[]>([]);
    const originalClient = ref<Client | null>(null);

    const hasChangedClient = computed(() => {
        if (!installationInfo.value || !originalClient.value) {
            return false;
        }

        return installationInfo.value.client?.id !== originalClient.value.id;
    });

    function onClientChange() {
        if (tableSelectedClient.value.length && installationInfo.value) {
            installationInfo.value.client = tableSelectedClient.value[0];
            isChangingClient.value = false;
            tableSelectedClient.value = [];
        }
    }

    function resetClient() {
        if (installationInfo.value && originalClient.value) {
            installationInfo.value.client = originalClient.value;
            isChangingClient.value = false;
        }
    }

    function beforeChangingClient() {
        tableSelectedClient.value = installationInfo.value!.client ? [installationInfo.value!.client] : [];
        isChangingClient.value = true;
    }
    // #endregion

    // #region yearlyCosts

    // #endregion

    async function fetchInstallation() {
        if (isCreating.value) {
            // #region clean object
            // installationInfo.value = {
            //     name: 'Teste info',
            //     lat: 41.39269,
            //     long: -8.523583,
            //     address: {
            //         road: 'Avenida Dom Afonso Henriques',
            //         city: 'Vila Nova de Famalicão',
            //         postcode: '4760-283',
            //     },
            //     country: 'PT',
            //     notes: 'NOTAS XPTO',
            //     installation: {
            //         id: 285,
            //         ref: 'e65df5c2-3644-46f8-8759-8a7e8e8f7fe0',
            //         name: 'Orlando reis',
            //         timezone: 'Europe/London',
            //         // @ts-ignore
            //         sysCreated: '2024-08-13T10:46:20.000Z',
            //         // @ts-ignore
            //         lastUpdated: '2024-08-30T07:19:22.000Z',
            //         platform: 'fronius',
            //         // @ts-ignore
            //         lastFetchedDate: '2024-08-29T00:00:00.000Z',
            //         continueFetching: true,
            //         lastFetchError: null,
            //         fetchErrorAt: null,
            //         plenoCost: null,
            //         active: true,
            //         inverterTotalPower: null,
            //         installationStartDate: null,
            //         status: null,
            //     },
            //     client: null,
            //     contract: {
            //         // @ts-ignore
            //         contractStartDate: '2024-09-01T00:00:00.000',
            //         contractTime: 1,
            //         // @ts-ignore
            //         contractEndDate: '2024-09-30T00:00:00.000',
            //         kWpAnualYield: 200,
            //         realInstalledPower: 4600,
            //         autoconsumption: 500,
            //         totalEnergy: 1650,
            //         totalAutoconsumption: 1354,
            //         businessModel: 'selfConsumption',
            //         businessType: 'PPA',
            //         currency: 'EUR',
            //         incomeType: 'variable',
            //     },
            // };
            // #endregion
            installationInfo.value = {
                name: '',
                lat: null,
                long: null,
                address: {
                    road: '',
                    city: '',
                    postcode: '',
                },
                country: '',
                notes: '',
                installation: null,
                client: null,
            };

            return;
        }

        isLoading.value = true;

        try {
            const { data } = await getInstallationInfo($route.params.id as string, {
                populate: ['installation', 'client', 'contract'],
            });
            installationInfo.value = data.data;
            originalClient.value = data.data.client || null;
            tableSelectedClient.value = data.data.client ? [data.data.client] : [];

            originalInstallation.value = data.data.installation || null;
            tableSelectedInstallation.value = data.data.installation ? [data.data.installation] : [];

            if (installationInfo.value.address) {
                installationInfo.value.address = {
                    ...installationInfo.value.address,
                    city: installationInfo.value.address.city || installationInfo.value.address.town,
                    road: installationInfo.value.address.road || installationInfo.value.address.neighbourhood,
                    postcode: installationInfo.value.address.postcode,
                };
            }
            nextTick(() => {
                originalInstallationInfo.value = _.cloneDeep(installationInfo.value);
            });
        } catch (error) {
        } finally {
            isLoading.value = false;
        }
    }

    async function saveInstallation() {
        if (!installationInfo.value) return;

        isSaving.value = true;

        try {
            const bodyToSend = {
                ...installationInfo.value,
                panelAreas: panelAreas.value,
                batteries: batteries.value,
                contracts: contracts.value,
            };

            if (bodyToSend.lat === '') {
                bodyToSend.lat = null;
            }

            if (bodyToSend.long === '') {
                bodyToSend.long = null;
            }

            let createdInstallationInfo: InstallationInfo | null = null;

            if (isCreating.value) {
                const { data } = await createInstallationInfo(bodyToSend);
                createdInstallationInfo = data.data;
            } else {
                await updateInstallationInfo(installationInfo.value.id as number, bodyToSend);
            }

            $alert.showAlert({
                color: 'success',
                text: 'Instalação guardada com sucesso',
            });
            if (isCreating.value) {
                $router.push({ name: 'InstallationForm', params: { id: createdInstallationInfo?.id?.toString() } });
            } else {
                reset();
                nextTick(() => {
                    fetchInstallation();
                });
            }
        } catch (error) {
            $alert.showAlert({
                color: 'error',
                text: 'Erro ao guardar a instalação',
            });
            throw error;
        } finally {
            isSaving.value = false;
        }
    }

    function reset() {
        installationInfo.value = null;
        contracts.value = {
            _create: [],
            _update: [],
            _delete: [],
        };
        panelAreas.value = {
            _create: [],
            _update: [],
            _delete: [],
        };
        batteries.value = {
            _create: [],
            _update: [],
            _delete: [],
        };
        currentTab.value = 'info';
        isChangingInstallation.value = false;
        tableSelectedInstallation.value = [];
        originalInstallation.value = null;
        isChangingClient.value = false;
        tableSelectedClient.value = [];
        originalClient.value = null;
    }

    fetchInstallation();

    function onExit() {
        console.log('exit', toRoute.value);
        forceExit.value = true;
        $router.push(toRoute.value);
    }

    function beforeUnload(e: BeforeUnloadEvent) {
        if (!_.isEqual(installationInfo.value, originalInstallationInfo.value)) {
            e.preventDefault();
            e.returnValue = '';
        }
    }

    onMounted(() => {
        window.addEventListener('beforeunload', beforeUnload);
    });

    onUnmounted(() => {
        window.removeEventListener('beforeunload', beforeUnload);
    });

    onBeforeRouteLeave((to, from) => {
        if (!_.isEqual(installationInfo.value, originalInstallationInfo.value) && !forceExit.value) {
            toRoute.value = to.fullPath;
            isClosingModalOpen.value = true;
            return false;
        }

        return true;
    });
</script>

<style></style>
